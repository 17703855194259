<template>
  <v-stepper v-model="e6" vertical>
    <v-stepper-step
      :complete="e6 > 1"
      step="1"
      color="identity white--text"
      editable
    >
      Perfil
      <small>Nombre, descripción y teléfono</small>
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card color="transparent" class="mb-12" max-width="500">
        <v-form ref="form">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  :label="'Nombre del proveedor'"
                  type="text"
                  prepend-icon="mdi-account"
                  required
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">
                <v-text-field
                  :label="'Código internacional'"
                  type="tel"
                  pattern="[\+]\d{3}[ ]?"
                  autocomplete="off"
                  maxlength="4"
                  prepend-icon="mdi-flag-variant-outline"
                  required
                  value="+52"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                  :label="'Móvil de contacto'"
                  type="tel"
                  pattern="\d{10}"
                  autocomplete="off"
                  maxlength="10"
                  prepend-icon="mdi-cellphone-message"
                  value="3310667865"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-btn color="identity" dark @click="e6 = 2">
        Continuar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 2"
      step="2"
      color="identity white--text"
      editable
    >
      Proyecto
      <small>Alcance</small>
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card color="transparent" class="mb-12" max-width="500">
        <v-form ref="form">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  :label="'Título del proyecto'"
                  type="text"
                  prepend-icon="mdi-ansible"
                  required
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-textarea
                  outlined
                  counter="150"
                  clearable
                  prepend-icon="mdi-alphabet-aurebesh"
                  label="Breve descripción"
                  rows="4"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-btn color="identity" dark @click="e6 = 3">
        Continuar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 3"
      step="3"
      color="identity white--text"
      editable
    >
      Actividades
      <small>Identificación de riesgos</small>
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card color="transparent" class="mb-12" max-width="700">
        <v-form ref="form">
          <v-container class="pa-5">
            <v-row no-gutters>
              <v-col cols="12">
                <div class="mt-5 mb-2 text-body-1 grey--text text--darken-3">
                  ¿Realizarán trabajos en las alturas?
                </div>
                <v-radio-group row mandatory class="ml-3">
                  <v-radio label="Si" value="radio-1" color="green"></v-radio>
                  <v-radio label="No" value="radio-2" color="red"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <div class="mt-5 mb-2 text-body-1 grey--text text--darken-3">
                  Enumera los materiales peligrosos con los que trabajarán:
                </div>
                <v-textarea
                  counter="150"
                  clearable
                  rows="4"
                  label="Materiales peligrosos"
                  outlined
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
      <v-btn color="identity" dark @click="e6 = 4">
        Continuar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 4"
      step="4"
      color="identity white--text"
      editable
    >
      Equipo de trabajo
      <small>Agrega el personal que ingresará</small>
    </v-stepper-step>

    <v-stepper-content step="4">
      <v-card color="transparent" class="mb-12">
        <template>
          <v-data-table
            :headers="headers"
            :items="people"
            sort-by="type"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Personas</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="identity"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Agregar
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Nombre"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.type"
                              label="ID"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.number"
                              label="Número de identificación"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancelar
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Guardar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      ><p class="text-caption">
                        En este proyecto no participará.
                      </p>
                      <p>¿Lo deseas quitar?</p></v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancelar</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >Si</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="identity" dark @click="initialize">
                Limpiar
              </v-btn>
            </template>
          </v-data-table>
        </template>
      </v-card>
      <v-btn color="identity" dark @click="e6 = 5">
        Continuar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 5"
      step="5"
      color="identity white--text"
      editable
    >
      Recursos
      <small>Agrega equipos y herramientas</small>
    </v-stepper-step>
    <v-stepper-content step="5">
      <v-card color="transparent" class="mb-12">
        <v-data-table
          :headers="headers2"
          :items="tools"
          sort-by="descn"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Equipo y herramientas</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog2" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="identity"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Agregar
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem2.qty"
                            label="Cantidad"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem2.descn"
                            label="Descripción (modelo)"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem2.number"
                            label="Número de serie"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close2">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save2">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete2" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    ><p class="text-caption">
                      En este proyecto no se utilizará.
                    </p>
                    ¿Lo deseas quitar?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete2"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm2"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem2(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem2(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="identity" dark @click="initialize">
              Limpiar
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <v-btn color="identity" dark @click="e6 = 6">
        Continuar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>

    <v-stepper-step
      :complete="e6 > 6"
      step="6"
      color="identity white--text"
      editable
    >
      Fechas
      <small>Programar los días</small>
    </v-stepper-step>
    <v-stepper-content step="6">
      <v-card color="transparent" class="mb-12" outlined>
        <v-date-picker
          :year-icon="checkIcon"
          reactive
          :full-width="fullWidth"
          v-model="dates"
          :multiple="multiple"
          header-color="identity"
          color="identity"
          elevation="2"
          :show-current="isEmpty"
          locale="es-mx"
          :landscape="landscape"
          :min="minDate()"
          :max="maxDate()"
          @touchstart.native.stop
          @touchmove.native.stop
          @touchend.native.stop
        ></v-date-picker>
      </v-card>
      <v-btn color="identity" dark @click="e6 = 7">
        Continuar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>

    <v-stepper-step step="7" color="identity white--text" editable>
      Horas
      <small>De entrada y salida</small>
    </v-stepper-step>
    <v-stepper-content step="7">
      <v-card color="transparent" class="mb-12" outlined>
        <v-container fill-height fluid class="ma-0 pa-0">
          <time-period :duarationMarginTop="'mt-3'" />
        </v-container>
      </v-card>
      <v-btn color="identity" dark @click="e6 = 1">
        Continuar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import TimePeriod from '@/components/time/TimePeriod'
export default {
  components: {
    TimePeriod
  },
  data: () => ({
    // Stepper
    e6: 1,
    // Stepper

    // Calendar
    landscape: false,
    multiple: true,
    // Calendar

    // Dialogs
    IDypes: ['INE', 'Licencia', 'Pasaporte'],
    selection: 'Licencia',
    dialog: false,
    dialog2: false,
    dialogDelete: false,
    dialogDelete2: false,
    headers: [
      {
        text: 'Nombre',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      { text: 'ID', value: 'type_number' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    headers2: [
      {
        text: 'Cantidad',
        align: 'start',
        sortable: false,
        value: 'qty'
      },
      { text: 'Descripción / Serie', value: 'descn_number' },
      { text: 'Acciones', value: 'actions', sortable: false }
    ],
    people: [],
    tools: [],
    editedIndex: -1,
    editedIndex2: -1,
    editedItem: {
      name: '',
      type: '',
      number: ''
    },
    defaultItem: {
      name: '',
      type: '',
      number: ''
    },
    editedItem2: {
      qty: '',
      descn: '',
      number: ''
    },
    defaultItem2: {
      qty: '',
      descn: '',
      number: ''
    }
  }),

  computed: {
    ...mapGetters('requests', ['draft']),
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo' : 'Editar'
    },
    isEmpty() {
      return !this.dates
    },
    fullWidth() {
      return this.$vuetify.breakpoint.smAndUp ? false : true
    },
    checkIcon() {
      return this.isEmpty ? 'mdi-calendar-blank' : 'mdi-calendar-check'
    },
    dates: {
      get: function() {
        return this.draft.date
      },
      set: function(value) {
        this.saveDraft({ key: 'date', value })
        this.updateValidSection({ section: 'date', value: !!value })
      }
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  methods: {
    ...mapActions('requests', ['saveDraft', 'updateValidSection']),

    initialize() {
      const peopleItems = [
        {
          name: 'Fernando Rivas',
          type: 'INE',
          number: '7376282892882'
        },
        {
          name: 'Hugo Temporal',
          type: 'Licencia',
          number: '56736636626711'
        }
      ]
      this.people = peopleItems.map(item => {
        return { ...item, ...{ type_number: `${item.type} ${item.number}` } }
      })

      const toolsItems = [
        {
          qty: 1,
          descn: 'Roto martillo DeWalt',
          number: 'A776R82I9B882'
        },
        {
          qty: 1,
          descn: 'Laptop HP',
          number: 'S56A7366E36626711'
        }
      ]
      this.tools = toolsItems.map(item => {
        return {
          ...item,
          ...{ descn_number: `${item.descn} / ${item.number}` }
        }
      })
    },

    editItem(item) {
      this.editedIndex = this.people.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    editItem2(item) {
      this.editedIndex2 = this.tools.indexOf(item)
      this.editedItem2 = Object.assign({}, item)
      this.dialog2 = true
    },

    deleteItem(item) {
      this.editedIndex = this.people.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItem2(item) {
      this.editedIndex2 = this.tools.indexOf(item)
      this.editedItem2 = Object.assign({}, item)
      this.dialogDelete2 = true
    },

    deleteItemConfirm() {
      this.people.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    deleteItemConfirm2() {
      this.tools.splice(this.editedIndex2, 1)
      this.closeDelete2()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    close2() {
      this.dialog2 = false
      this.$nextTick(() => {
        this.editedItem2 = Object.assign({}, this.defaultItem2)
        this.editedIndex2 = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete2() {
      this.dialogDelete2 = false
      this.$nextTick(() => {
        this.editedItem2 = Object.assign({}, this.defaultItem2)
        this.editedIndex2 = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.people[this.editedIndex], this.editedItem)
      } else {
        this.people.push(this.editedItem)
      }
      this.close()
    },

    save2() {
      if (this.editedIndex2 > -1) {
        Object.assign(this.tools[this.editedIndex2], this.editedItem2)
      } else {
        this.tools.push(this.editedItem2)
      }
      this.close2()
    },

    minDate() {
      return moment().format('YYYY-MM-DD')
    },

    maxDate() {
      return moment()
        .add(30, 'days')
        .format('YYYY-MM-DD')
    },

    dateFormatted() {
      return !this.isEmpty ? moment(this.dates).format('dddd, D MMMM') : ''
    }
  },

  created() {
    this.initialize()
    moment.locale('es-mx')
  }
}
</script>
